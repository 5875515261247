import { FC, useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { EffectFade, Keyboard, Mousewheel, Swiper as SwiperCore } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

export type Direction = "UP" | "DOWN"
export const TRANSITION_DURATION = 800

const StyledSwiper = styled(Swiper)<{ $disableSwipe: boolean }>`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.$disableSwipe &&
    `
  pointer-events: none;
  .swiper-slide {
    pointer-events: none !important;
  }
  `};
`
const StyledContainerEnd = styled.span`
  position: absolute;
  inset: 0;
  pointer-events: all;
  /* z-index: 1; */
`

type Props = {
  animation: boolean
  onSwipe: (dir: Direction) => void
}

const SwipeAnimation: FC<Props> = ({ animation, onSwipe, children }) => {
  const [disableSwipe, setDisableSwipe] = useState(false)
  const [swiperController, setSwiperController] = useState<SwiperCore>(null)

  const handleScroll = useCallback(() => {
    if (window.scrollY === 0) {
      setDisableSwipe(false)
      return
    }
    setTimeout(() => {}, 3000)
  }, [])

  useEffect(() => {
    if (!animation) {
      return
    }
    if (window.scrollY > 0) {
      swiperController?.slideTo(1)
    } else {
      setTimeout(() => {
        swiperController?.slideTo(1)
      }, 1000)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation, swiperController])

  if (!animation) {
    return <>{children}</>
  }
  return (
    <StyledSwiper
      modules={[Mousewheel, Keyboard, EffectFade]}
      direction="vertical"
      speed={0}
      slidesPerView={1}
      effect="fade"
      mousewheel
      keyboard
      onSlideChange={(swiper) => {
        onSwipe(swiper.activeIndex ? "DOWN" : "UP")
        if (swiper.activeIndex) {
          setTimeout(() => {
            setDisableSwipe(true)
          }, TRANSITION_DURATION)
        }
      }}
      onSwiper={setSwiperController}
      $disableSwipe={disableSwipe}
    >
      <SwiperSlide />
      <SwiperSlide />

      <StyledContainerEnd slot="container-end">{children}</StyledContainerEnd>
    </StyledSwiper>
  )
}

export default SwipeAnimation
