const BLOB_URL = process.env.BLOB_URL
const CDN_URL = process.env.CDN_URL

if (!BLOB_URL || !CDN_URL) {
  throw new Error("Please provide the API_URL environment variable.")
}

// export function that gets image url and replace the base url by environment variable
export const getImageCDNUrl = (imageUrl: string): string => {
  if (!imageUrl) {
    return ""
  }

  return imageUrl.replace(BLOB_URL, CDN_URL)
}

// export function that gets image url and split it and return array of base url and path
export const getImageBaseUrlAndPath = (imageUrl: string): string[] => {
  if (!imageUrl) {
    return ["", ""]
  }
  const url = getImageCDNUrl(imageUrl)
  const baseUrl = CDN_URL
  // split url after the baseUrl
  const urlSplit = url.split(baseUrl)
  if (urlSplit.length < 2) {
    return ["", ""]
  }

  return [`${urlSplit[0]}${baseUrl}`, urlSplit[1]]
}

const normalizeSrc = (src) => {
  return src.startsWith("/") ? src.slice(1) : src
}

export const cloudflareLoader = ({ src, width, quality }) => {
  const params = [`width=${width}`, "format=auto"]
  if (quality) {
    params.push(`quality=${quality}`)
  }
  const paramsString = params.join(",")

  const [baseUrl, path] = getImageBaseUrlAndPath(src)
  if (!baseUrl || !path) {
    return src
  }

  return `${baseUrl}/cdn-cgi/image/${paramsString}/${normalizeSrc(path)}`
}
