import NextImage from "common/NextImage"
import { getImageCDNUrl } from "common/utils/imageMap"
import { useLocale } from "next-intl"
import React, { useState } from "react"
import styled from "styled-components"
import QPlayer from "./QPlayer"

export type QMediaComponentProps = {
  imageUrl?: string
  videoUrl?: string
  className?: string
  altText?: string
  poster?: string
  type?: any
  videoName?: string
  description?: string
  uploadDate?: string
  thumbnailUrl?: string
}
//Styles
const StyledImageWrapper = styled.div<{ $rtl: boolean }>`
  flex-grow: 1;
  height: 240px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  position: relative;

  ${(p) => p.theme.breakpoints.up("md")} {
    width: 100%;
    height: 100%;
    position: absolute;

    right: ${(p) => (!p.$rtl ? "0 !important" : "")};
    left: ${(p) => (p.$rtl ? 0 : "")};
  }
`

// Render
const QMediaComponent: React.FC<QMediaComponentProps> = ({
  imageUrl,
  altText,
  videoUrl,
  className,
  poster,
  type,
  videoName,
  description,
  uploadDate,
  thumbnailUrl,
}) => {
  const locale = useLocale()
  const [videoLoaded, setVideoLoaded] = useState(false)
  const isVideo = (type as unknown as string) === "Video" ? true : false
  return (
    <>
      {imageUrl && !isVideo ? (
        <StyledImageWrapper className={className} $rtl={locale === "he"}>
          <NextImage image={{ imageUrl: imageUrl, alt: altText }} priority />
        </StyledImageWrapper>
      ) : (
        <StyledImageWrapper className={className} $rtl={locale === "he"}>
          <QPlayer
            videoUrl={getImageCDNUrl(isVideo ? imageUrl : videoUrl)}
            altText={altText}
            onReady={() => setVideoLoaded(true)}
            videoName={videoName}
            description={description}
            uploadDate={uploadDate}
            thumbnailUrl={thumbnailUrl}
          />
          {!videoLoaded && poster && (
            <NextImage image={{ imageUrl: poster, alt: altText }} priority />
          )}
        </StyledImageWrapper>
      )}
    </>
  )
}

export default QMediaComponent
