import { Box, Container } from "@material-ui/core"
import SwipeAnimation, {
  TRANSITION_DURATION,
} from "components/asset/components/SwipeAnimation"
import QMediaComponent from "components/custom/QMediaComponent"
import { useLocale } from "next-intl"
import { FC, ReactNode, useEffect, useState } from "react"
import styled, { css } from "styled-components"

export const SCROLL_MARGIN_TOP = 0

const transition = css`
  transition: all ${TRANSITION_DURATION}ms ease-in-out;
`

const StyledContainer = styled(Container).attrs({ maxWidth: "lg" })`
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: 0;
  }
`
const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${(p) => p.theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
  }
`
const StyledImageWrapper = styled.div<{
  $desktopImageHeight?: number
  $insideContainer?: boolean
}>`
  max-height: 240px;
  width: 100%;
  align-self: center;

  ${(p) => p.theme.breakpoints.up("md")} {
    position: sticky;
    width: ${(props) =>
      props.$insideContainer ? `min(40vw, calc(1920px * 0.4))` : `40vw`};
    left: 0;
    top: 60px;
    flex-shrink: 0;
    z-index: 1;
    height: calc(100vh - 60px);
    ${(props) =>
      props.$desktopImageHeight &&
      `
    height: ${props.$desktopImageHeight}px;
    `};
    max-height: none;
    align-self: initial;
  }
`

const StyledInfoContainer = styled.div<{
  $noPadding: boolean
  $hasAnimation: boolean
  $insideContainer?: boolean
}>`
  display: flex;
  flex-direction: column;
  ${transition}
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: 0 15px;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    --min-vw: ${(props) =>
      props.$insideContainer ? `min(10vw, calc(1920px * 0.1))` : `10vw`};
    width: calc(50% + var(--min-vw));
    padding-top: ${(props) => (props.$noPadding ? "0" : "30px")};
    flex-shrink: 0;
    padding-inline-end: ${(props) => (props.$noPadding ? "0" : "50px")};
    margin-top: ${(props) =>
      props.$hasAnimation ? `${SCROLL_MARGIN_TOP}px ` : "0"};
  }
`
const StyledMediaComponent = styled(QMediaComponent)`
  width: 100%;

  ${(p) => p.theme.breakpoints.up("md")} {
    width: 100%;
    height: 100%;
  }
`

const StyledContentTransitionWrapper = styled(Box)<{
  $animateIn: boolean
  $noTransition: boolean
}>`
  ${(p) => (!p.$noTransition ? transition : "")}
  transition-delay: ${(props) => (props.$animateIn ? "0.2s" : "0s")};

  transform: ${(props) =>
    !props.$animateIn ? "translate3d(0,100vh,0)" : "translate3d(0,0,0)"};
  ${(p) => p.theme.breakpoints.up("md")} {
    transform: ${(props) =>
      !props.$animateIn ? "translate3d(0,20vw,0)" : "translate3d(0,0,0)"};
  }
  opacity: ${(props) => (props.$animateIn ? 1 : 0)};
`

const StyledImageTransitionWrapper = styled(Box)<{
  $animateIn: boolean
  $noTransition: boolean
  $isRtl: boolean
}>`
  ${(p) => (!p.$noTransition ? transition : "")}
  ${(p) => p.theme.breakpoints.up("md")} {
    max-width: none;
    width: ${(props) => (!props.$animateIn ? "100vw !important" : "100%")};
    left: ${(props) => (props.$isRtl ? "0" : "auto")};
    right: ${(props) => (!props.$isRtl ? "0" : "auto")};
    position: absolute;
    height: 100%;
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    height: ${(props) => (!props.$animateIn ? "calc(100vh - 60px)" : "250px")};
  }
  [slot="container-end"] > div {
    height: 100%;
  }
  /* transform: scale(${(props) => (props.$animateIn ? 1 : 1.3)});
  transform-origin: top; */
`

type Props = {
  customImage?: () => ReactNode
  imageUrl?: string
  altText?: string
  type?: any
  poster?: string
  noPadding?: boolean
  desktopImageHeight?: number
  animation?: boolean
  animationDone?: (done: boolean) => void
  insideContainer?: boolean
  close?: boolean
}

const StickyImagePageLayout: FC<Props> = ({
  customImage,
  imageUrl,
  altText,
  type,
  poster,
  noPadding,
  children,
  desktopImageHeight,
  animation,
  animationDone,
  insideContainer,
  close,
}) => {
  // useEffect with scroll event to detect when to animate in
  const [animateIn, setAnimateIn] = useState(!animation)
  const [noTransition, setNoTransition] = useState(!animation)
  const locale = useLocale()
  useEffect(() => {
    if (window.scrollY > 10) {
      setAnimateIn(true)
      setNoTransition(true)
    }
  }, [])

  useEffect(() => {
    if (close) {
      setAnimateIn(true)
    }
  }, [close])

  useEffect(() => {
    if (animateIn) {
      setTimeout(() => {
        animationDone?.(true)
      }, TRANSITION_DURATION * 2)
    } else {
      animationDone?.(false)
      setNoTransition(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animateIn])
  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledInfoContainer
          $noPadding={noPadding}
          $hasAnimation={animation}
          $insideContainer={insideContainer}
        >
          <StyledContentTransitionWrapper
            $animateIn={animateIn}
            $noTransition={noTransition}
          >
            {children}
          </StyledContentTransitionWrapper>
        </StyledInfoContainer>
        <StyledImageWrapper
          $desktopImageHeight={desktopImageHeight}
          $insideContainer={insideContainer}
        >
          <StyledImageTransitionWrapper
            $animateIn={animateIn}
            $noTransition={noTransition}
            $isRtl={locale === "he"}
          >
            <SwipeAnimation
              animation={animation}
              onSwipe={(dir) => setAnimateIn(dir === "DOWN")}
            >
              {customImage ? (
                customImage()
              ) : (
                <StyledMediaComponent
                  imageUrl={imageUrl}
                  altText={altText}
                  type={type}
                  poster={poster}
                />
              )}
            </SwipeAnimation>
          </StyledImageTransitionWrapper>
        </StyledImageWrapper>
      </StyledWrapper>
    </StyledContainer>
  )
}

export default StickyImagePageLayout
