import { IconButton } from "@material-ui/core"
import PauseIcon from "@material-ui/icons/Pause"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import VolumeDownIcon from "@material-ui/icons/VolumeDown"
import VolumeOffIcon from "@material-ui/icons/VolumeOff"
import { VideoJsonLd } from "next-seo"
import { useRef, useState } from "react"
import ReactPlayer, { ReactPlayerProps } from "react-player"
import styled from "styled-components"

const StyledPlayerWrap = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
const StyledPlayer = styled(ReactPlayer).attrs({
  width: "100%",
  height: "100%",
})<{ $videoContained: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  video {
    object-fit: ${(p) =>
      p.$videoContained ? `contain !important` : `cover !important`};
  }
`

const StyledIconButton = styled(IconButton)``
const StyledControler = styled.div`
  z-index: 2;
  position: absolute;
  padding: 10px;
  width: 80px;
  /* height: 40px; */
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  bottom: 20px;
  ${(p) => p.theme.breakpoints.up("md")} {
    right: 20px;
    bottom: 30px;
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    left: 20px;
  }
`

const playerSettings: ReactPlayerProps = {
  controls: false,
  autoPlay: true,
}

type QPlayerProps = {
  videoUrl: string
  altText: string
  videoContained?: boolean
  videoName: string
  description: string
  uploadDate: string
  thumbnailUrl: string

  onReady?: () => void
}

const QPlayer = ({
  videoUrl,
  altText,
  videoContained = false,
  videoName,
  description,
  uploadDate,
  thumbnailUrl,
  onReady,
}: QPlayerProps) => {
  const player = useRef()
  const [playing, setPlaying] = useState(true)
  const [mute, setMute] = useState(true)
  return (
    <StyledPlayerWrap>
      <VideoJsonLd
        name={videoName}
        description={description}
        uploadDate={uploadDate}
        thumbnailUrl={thumbnailUrl}
      />
      <StyledPlayer
        autoPlay
        url={videoUrl}
        ref={player}
        muted={mute}
        loop={true}
        playsinline
        playing={playing}
        config={{
          file: {
            attributes: {
              "aria-label": `סרטון המתאר את ${altText}`,
            },
          },
        }}
        $videoContained={videoContained}
        onReady={(e) => {
          const player = e.getInternalPlayer()
          player.setAttribute("muted", "true")
          onReady?.()
        }}
        {...playerSettings}
      />
      <StyledControler>
        {!mute ? (
          <StyledIconButton
            aria-label="השתק סרטון"
            onClick={() => {
              setMute(true)
            }}
          >
            <VolumeOffIcon />
          </StyledIconButton>
        ) : (
          <StyledIconButton
            aria-label="השמע סרטון"
            onClick={() => setMute(false)}
          >
            <VolumeDownIcon />
          </StyledIconButton>
        )}
        {playing ? (
          <StyledIconButton
            aria-label="עצור סרטון"
            onClick={() => {
              setPlaying(false)
            }}
          >
            <PauseIcon />
          </StyledIconButton>
        ) : (
          <StyledIconButton
            aria-label="נגן סרטון"
            onClick={() => setPlaying(true)}
          >
            <PlayArrowIcon />
          </StyledIconButton>
        )}
      </StyledControler>
    </StyledPlayerWrap>
  )
}

export default QPlayer
